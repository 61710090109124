import "Components/MonacoEditorStandalone";

interface IEditStartPageData {
	previewUrl: string;
}

interface IEditorOptions {
	content: HTMLTextAreaElement;
	data: IEditStartPageData;
	editor: HTMLElement;
	loading: HTMLElement;
	preview: HTMLElement;
	showEditor: HTMLElement;
	showPreview: HTMLElement;
}

function showElement(element: HTMLElement) {
	element.classList.remove("d-none");
	element.classList.add("d-block");
}

function hideElement(element: HTMLElement) {
	element.classList.remove("d-block");
	element.classList.add("d-none");
}

function setupEditor(options: IEditorOptions) {
	const { data, editor, loading, preview, showEditor, showPreview, content } = options;

	async function showRemotePreview() {
		try {
			const response = await fetch(data.previewUrl, {
				body: JSON.stringify({
					content: content.value
				}),
				credentials: "same-origin",
				headers: {
					"Accept": "text/html",
					"Content-Type": "application/json",
					"X-XSRF-TOKEN": document.querySelector<HTMLInputElement>("input[name=__RequestVerificationToken]")?.value ?? ""
				},
				method: "POST"
			});

			if (response.status === 200) {
				preview.innerHTML = await response.text();
				showElement(preview);
				hideElement(loading);
			} else {
				preview.innerHTML = `${response.status} ${response.statusText}`;
				showElement(preview);
				hideElement(loading);
			}
		} catch (error) {
			preview.innerHTML = error?.toString() ?? "Unknown error";
			showElement(preview);
			hideElement(loading);
			throw error;
		}
	}

	showEditor.addEventListener("click", () => {
		showEditor.classList.add("active");
		showPreview.classList.remove("active");
		editor.style.visibility = "visible";
		hideElement(preview);
	});

	showPreview.addEventListener("click", () => {
		showEditor.classList.remove("active");
		showPreview.classList.add("active");
		editor.style.visibility = "collapse";
		showElement(loading);

		void showRemotePreview();
	});
}

const editStartPageDataElement = document.getElementById("edit-start-page-data");
if (editStartPageDataElement) {
	const editStartPageData = JSON.parse(editStartPageDataElement.textContent ?? "{}") as IEditStartPageData;
	const editor = document.getElementById("editor");
	const loading = document.getElementById("loading");
	const preview = document.getElementById("preview");
	const showEditor = document.getElementById("showEditor");
	const showPreview = document.getElementById("showPreview");
	const content = document.getElementById("content") as HTMLTextAreaElement | null;

	if (editor && loading && preview && showEditor && showPreview && content) {
		setupEditor({
			content,
			data: editStartPageData,
			editor,
			loading,
			preview,
			showEditor,
			showPreview
		});
	}
}

export { };
